#login{
    position: fixed;
    z-index: 50;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .6);
    padding: 0;
}

#login .box{
    border: 1px solid gray;
}
#login .container{
    top: 20vh;
}

#login .field.buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 1016px){
    #login .container{
        top: 0vh;
    }
}