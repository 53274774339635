/* @import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,500,500i,600,600i,700,700i&display=swap"); */
/*
#glam-header .hero-body{
  padding-top: 1.5rem;
}

#glam-header .navbar .media-left{
  margin-right: .2rem;
  font-size: 40px;
}

#glam-header .hero-head .title,
#glam-header .hero-head .subtitle,
#glam-header .hero-head a.navbar-item.is-active
{
  color: 0;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}
#glam-header .hero-head a.navbar-item{
  color: black;
}

#glam-header .navbar-item img{
  max-height: unset;
}
#glam-header .navbar-burger{
  top: 8px;
  scale: 1.4;
  margin-right: 24px;
} */

#glam-header a{
  min-width: 4rem;
  margin-right: 1rem;
}

#glam-header .navbar-brand p.subtitle{
  color: #CCC;
}

#glam-header .navbar-brand p.title{
  color: #AAA;
}

.glam-header-container{
  padding-bottom: 2px;
}